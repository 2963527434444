





















































































import {Component, PropSync, Vue} from "vue-property-decorator";
import Options from "@/models/vue/Options";
import Notification from "@/models/Notification";
import NotificationService from "@/services/NotificationService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import LangModule from "@/store/LangModule";
import Rules from "@/services/tool/Rules";
import SnackbarModule from "@/store/SnackbarModule";
import {Editor} from "@toast-ui/vue-editor";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import '@toast-ui/editor/dist/toastui-editor.css'
import Company from "@/models/Company";
import Handler from "@/handlers/Handler";
import CompanyService from "@/services/CompanyService";

@Component({
  components: {Editor}
})
export default class CreateNotificationDialog extends Vue {

  notification: SingleItem<Notification> = { item: new Notification() }

  lang: any = getModule(LangModule).lang

  get rules() { return Rules }
  loading: boolean = false
  email: string = ""
  receiversEmails: string[] = []
  title: string = ""
  companies: MultipleItem<Company> = { items: [], totalItems: 0 }
  editorOptions = {hideModeSwitch: true}
  companySearch: string = ""

  @PropSync('dialog', { type: Boolean }) syncedDialog!: boolean

  created() {
    this.refresh()
  }

  refresh() {
    this.getCompanies()
  }

  async getCompanies() {
    await Handler.getItems(this, this.companies, () =>
      CompanyService.getCompanies2(0, 10, this.companySearch)
    )
  }

  createNotification() {
    if (this.receiversEmails.length > 0 || this.notification.item.targetCompanyId != undefined) {
      getModule(DialogModule).showDialog(new Dialog(
          this.lang.warning,
          this.lang.notificationCreateQuestion,
          async () => {
            await Handler.getItem(this, this.notification, () => NotificationService.postNotification(this.notification.item))
          }
      ))
    } else {
      getModule(SnackbarModule).makeToast("Debe añadir al menos un destinatario.")
    }
  }

  addReceiver() {
    if (this.rules.email(this.email) == true) {
      if (this.receiversEmails.includes(this.email)) { getModule(SnackbarModule).makeToast("El correo ya esta en los destinatarios.") }
      else {
        this.receiversEmails.push(this.email)
      }
    } else {
      getModule(SnackbarModule).makeToast("Correo invalido.")
    }
  }

  onEditorChange() {  // @ts-ignore
    this.notification.item.message = this.$refs.toastuiEditor.invoke('getMarkdown')
  }
}
